<template>
  <v-text-field
    v-model="inputText"
    :type="type"
    :outlined="outlined"
    :label="label"
    :readonly="readonly"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :placeholder="placeholder"
    :single-line="singleLine"
    :hint="hint"
    :persistent-hint="persistentHint"
    :required="required"
    :rules="validationRules"
    hide-details="auto"
    dense
    @input="onInput"
    @click:append="appendClick"
  />
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  name: 'AcInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validationRules: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const inputText = ref(props.value)

    const onInput = val => {
      emit('input', val)
    }
    const appendClick = val => {
      emit('appendClick', val)
    }

    watch(
      () => props.value,
      val => (inputText.value = val),
    )

    return {
      inputText,
      onInput,
      appendClick,
    }
  },
}
</script>

<style lang="scss" scoped></style>
